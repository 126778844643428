// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-dt-js": () => import("./../../../src/pages/dt.js" /* webpackChunkName: "component---src-pages-dt-js" */),
  "component---src-pages-euros-js": () => import("./../../../src/pages/euros.js" /* webpackChunkName: "component---src-pages-euros-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-videprinter-js": () => import("./../../../src/pages/videprinter.js" /* webpackChunkName: "component---src-pages-videprinter-js" */),
  "component---src-pages-videprinter-new-js": () => import("./../../../src/pages/videprinter-new.js" /* webpackChunkName: "component---src-pages-videprinter-new-js" */),
  "component---src-pages-wc-js": () => import("./../../../src/pages/wc.js" /* webpackChunkName: "component---src-pages-wc-js" */)
}

